<template>
  <div>
    <div class="h4">I have voted for Liquifi on all the required sites</div>

    <!-- <div class="d-flex flex-row justify-content-center mt-3" v-for="site in siteNames" :key="site">
      <ExternalLink color="primary" :label="`Vote for Liquifi on '${site}'`" :url="getSiteUrl(site)" icon />
    </div> -->

    <b-form-checkbox class="mt-3" v-model="selected" name="ready">
      Ready
    </b-form-checkbox>

    <b-button
        block
        variant="primary"
        class="button-flat mt-3"
        v-on:click="onNextStep"
        :disabled="!selected"
    >Next</b-button>
  </div>

</template>

<script>

const VOTE_ON_SITE_NAMES = {
  COINSNIPER: 'coinsniper',
  COINHUT: 'coinhunt',
  COINVOTE: 'coinvote',
  FRESHCOINS: 'freshcoins',
  RIVAL_FINANCE: 'rival.finance',
  CRYPTOTOKENSPACE: 'cryptotokenspace',
  COINHUNTERS: 'coinhunters',
  ICOHOLDER: 'icoholder',
  COINBOOM: 'coinboom',
  COINDISCOVERY: 'coindiscovery'
};

const VOTE_ON = [
  VOTE_ON_SITE_NAMES.COINSNIPER,
  VOTE_ON_SITE_NAMES.COINHUT,
  VOTE_ON_SITE_NAMES.COINVOTE,
  VOTE_ON_SITE_NAMES.FRESHCOINS,
  VOTE_ON_SITE_NAMES.RIVAL_FINANCE,
  VOTE_ON_SITE_NAMES.CRYPTOTOKENSPACE,
  VOTE_ON_SITE_NAMES.COINHUNTERS,
  VOTE_ON_SITE_NAMES.ICOHOLDER,
  VOTE_ON_SITE_NAMES.COINBOOM,
  VOTE_ON_SITE_NAMES.COINDISCOVERY
];

const VOTE_LIST = {
  [VOTE_ON_SITE_NAMES.COINSNIPER]: 'https://coinsniper.net/coin/10051',
  [VOTE_ON_SITE_NAMES.COINHUT]: 'https://coinhunt.cc/coin/214298987',
  [VOTE_ON_SITE_NAMES.COINVOTE]: 'https://coinvote.cc/coin/Liquifi_V2_DAO_Token',
  [VOTE_ON_SITE_NAMES.FRESHCOINS]: 'https://freshcoins.io/YTgGZ0Ag9IiL2opfIDQQ/coin/liquifi-v2-dao-token/lqfv2',
  [VOTE_ON_SITE_NAMES.RIVAL_FINANCE]: 'https://rival.finance/0xdABd06C46b812B40D77Cd62c3417Cb40851783bb',
  [VOTE_ON_SITE_NAMES.CRYPTOTOKENSPACE]: 'https://cryptotokenspace.com/Tokens/Token?contractAddress=0xdABd06C46b812B40D77Cd62c3417Cb40851783bb',
  [VOTE_ON_SITE_NAMES.COINHUNTERS]: 'https://coinhunters.cc/tokens/LiquifiV2DAOToken',
  [VOTE_ON_SITE_NAMES.ICOHOLDER]: 'https://icoholder.com/en/liquifi-1001203',
  [VOTE_ON_SITE_NAMES.COINBOOM]: 'https://coinboom.net/coin/liquifi-v2-dao-token-552',
  [VOTE_ON_SITE_NAMES.COINDISCOVERY]: 'https://coindiscovery.app/coin/liquifi-v2-dao-token',
};

export default {
  name: 'VoteStep',
  components: {
    // ExternalLink: () => import('@/components/ui/ExternalLink')
  },

  data: function () {
    return {
      siteNames: VOTE_ON,
      voteIndx: 0,
      selected: false,
    };
  },

  methods: {
    onNextStep() {
      this.$emit('next', true);
    },

    getSiteUrl(site) {
      return VOTE_LIST[site];
    }
  }
};
</script>

<style scoped lang="scss">

</style>
